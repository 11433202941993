import { DateTime, Interval } from 'luxon';

import {
  AssignmentRead,
  DispatchTripRead,
} from 'src/common/external/bambi-api/bambiApi';

const TIME_WINDOW_MINUTES = 15;

export function getPotentialMultiloadTrips(
  originatingTrip: DispatchTripRead,
  otherTrips: DispatchTripRead[],
  assignment: AssignmentRead,
  multiLoadId?: string
): string[] {
  // Convert trip times to DateTime objects
  const tripPickup = DateTime.fromISO(originatingTrip.scheduled_pickup_at);
  const tripDropoff = DateTime.fromISO(originatingTrip.scheduled_dropoff_at);

  const windowStart = tripPickup.minus({ minutes: TIME_WINDOW_MINUTES });
  const windowEnd = tripDropoff.plus({ minutes: TIME_WINDOW_MINUTES });
  const originatingTripInterval = Interval.fromDateTimes(
    windowStart,
    windowEnd
  );

  const potentialMultiloadTrips: DispatchTripRead[] = otherTrips.filter(
    (trip) => {
      if (trip.id === originatingTrip.id || trip.id.includes('preview')) {
        return false;
      }
      // Not trips assigned to others -- We only want unassigned and trips that are assigned to the current assignment
      // Potentially could allow the flow to move trips between assignments but like... how many popups and branches do we want?
      if (trip.assignment_id && trip.assignment_id !== assignment.id) {
        return false;
      }

      // Trips that are already in the multi-load are automatically potential multi-load trips
      if (
        typeof multiLoadId === 'string' &&
        trip.multiload_id === multiLoadId
      ) {
        return true;
      }
      // Check if unassigned trip's pickup or dropoff falls within the time window
      // TODO: We'll also have to check if payers allow multiloads together
      // Potentially also if trips are within the assignment's schedule, although the bulkAssignment validate/assign ep
      // may allow for this to be overridden so... leaving it out for now
      return (
        originatingTripInterval.contains(
          DateTime.fromISO(trip.scheduled_pickup_at)
        ) ||
        originatingTripInterval.contains(
          DateTime.fromISO(trip.scheduled_dropoff_at)
        )
      );
    }
  );
  return potentialMultiloadTrips.map((trip) => trip.id);
}
