import { convertISODateTimePartsToUTCISO } from 'src/common/date/convertISODateTimePartsToUTCISO';
import {
  Trip,
  TripsBulkCreateWrite,
} from 'src/common/external/bambi-api/bambiApi';
import { SelectOptionWithColor } from 'src/common/primitives/Select';

export function adaptFormValuesToTripsBulkCreate(
  formValues: any
): TripsBulkCreateWrite {
  return {
    trips: formValues.trips.map((trip: any) => {
      return {
        passenger: {
          first_name: formValues.passenger.first_name,
          last_name: formValues.passenger.last_name,
          gender: formValues.passenger.gender,
          weight: formValues.passenger.weight,
          needs_bariatric_transport:
            formValues.passenger.needs_bariatric_transport,
          email:
            formValues.passenger.email === null
              ? ''
              : formValues.passenger.email,
          notes: formValues.passenger.notes,
          ...(formValues.passenger.dob && { dob: formValues.passenger.dob }),
          ...(formValues.passenger.phone_number && {
            phone_number: formValues.passenger.phone_number,
          }),
          ...(formValues.passenger.id && { id: formValues.passenger.id }),
        },
        external_trip_id: trip.external_trip_id,
        payer_passenger_external_id:
          formValues.pricing.payer_passenger_external_id,
        payer_id: formValues.pricing.payer,
        input_price_cents: trip.input_price_cents || 0,
        is_price_locked: trip.is_price_locked,
        payment_method: formValues.pricing.payment_method,
        pickup: {
          place_id: trip.pickupLocation.address.value,
          address_details: trip.pickupLocation.address_details,
          num_stairs: trip.pickupLocation.stairs,
          stair_equipment: trip.pickupLocation.stair_equipment,
          contact_name: trip.pickupLocation.contact_name,
          contact_phone_number: trip.pickupLocation.contact_phone_number
            ? trip.pickupLocation.contact_phone_number
            : null,
          driver_notes: trip.pickupLocation.driver_notes,
        },
        dropoff: {
          place_id: trip.dropoffLocation.address.value,
          address_details: trip.dropoffLocation.address_details,
          num_stairs: trip.dropoffLocation.stairs,
          stair_equipment: trip.dropoffLocation.stair_equipment,
          contact_name: trip.dropoffLocation.contact_name,
          contact_phone_number: trip.dropoffLocation.contact_phone_number
            ? trip.dropoffLocation.contact_phone_number
            : null,
          driver_notes: trip.dropoffLocation.driver_notes,
        },
        scheduled_pickup_at: convertISODateTimePartsToUTCISO({
          date: trip.timing.date,
          time: trip.timing.pickup_time,
        }),
        appointment_at: trip.timing.appointment_time
          ? convertISODateTimePartsToUTCISO({
              date: trip.timing.date,
              time: trip.timing.appointment_time,
            })
          : undefined,
        is_will_call: trip.timing.is_will_call,
        is_wait_and_return_outbound: trip.is_wait_and_return_outbound,
        is_wait_and_return_inbound: trip.is_wait_and_return_inbound,
        space_type: formValues.service_details.space_type,
        service_type: formValues.service_details.service_type,
        dispatcher_notes: trip.dispatcher_notes,
        is_oxygen_required: formValues.service_details.is_oxygen_required,
        oxygen_liters_per_min: formValues.service_details.oxygen_liters_per_min,
        num_attendants_needed: formValues.service_details.num_attendants_needed,
        num_accompanying_passengers:
          formValues.service_details.num_accompanying_passengers,
        booked_by_name: formValues.booked_by_name,
        booked_by_phone_number: formValues.booked_by_phone_number,
        booked_by_phone_extension: formValues.booked_by_phone_extension,
        load_time_seconds: trip.load_time_seconds
          ? trip.load_time_seconds
          : null,
        unload_time_seconds: trip.unload_time_seconds
          ? trip.unload_time_seconds
          : null,
        tag_ids: trip.trip_tags?.map(
          (option: SelectOptionWithColor) => option.value
        ),
        authorization_id: formValues.pricing.authorization_id,
        insurance_policy_id: formValues.pricing.insurance_policy_id,
        must_provide_wheelchair: formValues.must_provide_wheelchair,
      } as unknown as Trip;
    }),
  };
}
