import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from 'src/app/store';
import {
  DispatchTripRead,
  NewStatusEnum,
  OverrideStatusEnum,
  PatchedTrip,
  TripRead,
  useLazyAssignmentsRetrieveQuery,
  useLazyTripsRetrieveQuery,
  useTripsDestroyMutation,
  useTripsMultiloadDestroyMutation,
} from 'src/common/external/bambi-api/bambiApi';
import { addATrip } from 'src/features/add-trip/addATrip.slice';
import { useTripRepository } from 'src/features/add-trip/useAddATripOnSubmit/useTripRepository';
import {
  dispatcherSlice,
  execTripMenuAction,
  onRequestedTripAssignment,
  onTripAssignmentInvalid,
  onTripMenuCancelRequest,
  setModalConfirmTripActivationTripId,
  showDispatchModal,
} from 'src/features/dispatch/dispatcher.slice';
import {
  setSelectedTrip,
  setShowTripTagsModal,
} from 'src/features/trip/trip.slice';

import { useRemoveTripFromMultiLoad } from './useRemoveTripFromMultiLoad';

export function useTripMenuActions(skipDispatchCall = false) {
  const dispatch = useAppDispatch();
  const tripRepository = useTripRepository();
  const [getTrip] = useLazyTripsRetrieveQuery();
  const [deleteTrip] = useTripsDestroyMutation();
  const navigate = useNavigate();

  const { updateDriverNotes } = useUpdateDriverNotesActions();

  const [deleteTripMultiLoad] = useTripsMultiloadDestroyMutation();

  const [getAssignment] = useLazyAssignmentsRetrieveQuery();
  const removeTripFromMultiLoad = useRemoveTripFromMultiLoad();

  return {
    updateDriverNotes,
    delete: (tripId: string) => {
      dispatch(
        execTripMenuAction({
          action: async () => {
            await deleteTrip({
              id: tripId,
            });
          },
          successMessage: 'Trip deleted successfully!',
          errorMessage: 'Error deleting trip',
        })
      );
    },
    viewEdit: async (tripId: string) => {
      //TODO handle loading and errors
      const res = await getTrip({
        id: tripId,
      });
      dispatch(addATrip.actions.setSelectedTrip(res.data));
      dispatch(addATrip.actions.open());
    },
    addEditTags: (trip: TripRead) => {
      dispatch(setSelectedTrip(trip));
      dispatch(setShowTripTagsModal(true));
    },
    clone: async (tripId: string) => {
      //TODO handle loading and errors
      const res = await getTrip({
        id: tripId,
      });
      if (!res.data) return;

      const tripToClone: TripRead = {
        ...res.data,
      };
      dispatch(addATrip.actions.cloneTrip(tripToClone));
    },
    ontime: (tripId: string) => {
      dispatch(
        execTripMenuAction({
          action: async () => {
            // TODO: Will need to be uncommented
            // Not seeing a map with new TripEventEventTypeEnum
            //await tripRepository.ontime(tripId);
          },
          successMessage: 'Status set to On-Time',
          errorMessage: 'Error setting status to on-time',
        })
      );
    },
    delay: (tripId: string) => {
      dispatch(
        execTripMenuAction({
          action: async () => {
            // TODO: Will need to be uncommented
            // await tripRepository.delay(tripId);
          },
          successMessage: 'Status set to delayed',
          errorMessage: 'Error setting status to delayed',
        })
      );
    },
    cancel: async (tripId: string) => {
      const res = await getTrip({
        id: tripId,
      });
      dispatch(onTripMenuCancelRequest({ trip: res.data || null }));
    },
    uncancel: (tripId: string) => {
      dispatch(
        execTripMenuAction({
          action: async () => {
            await tripRepository.uncancel(tripId);
          },
          successMessage: 'Trip Uncanceled',
          errorMessage: 'Error canceling trip',
        })
      );
    },
    lock: (tripId: string) => {
      dispatch(
        execTripMenuAction({
          action: async () => {
            await tripRepository.lock(tripId);
          },
          successMessage: 'Trip locked',
          errorMessage: 'Error locking trip',
        })
      );
    },
    unlock: (tripId: string) => {
      dispatch(
        execTripMenuAction({
          action: async () => {
            await tripRepository.unlock(tripId);
          },
          successMessage: 'Trip unlocked',
          errorMessage: 'Error unlocking trip',
        })
      );
    },
    assign: async (
      tripId: string,
      assignmentId: string,
      status: OverrideStatusEnum
    ) => {
      const validateAssignmentResponse =
        await tripRepository.validateAssignment(tripId, assignmentId);
      if (validateAssignmentResponse.can_assign) {
        if (status === 'requested') {
          dispatch(
            onRequestedTripAssignment({
              tripId,
              assignmentId,
            })
          );
        } else {
          dispatch(
            execTripMenuAction({
              action: async () => {
                await tripRepository.assign(tripId, assignmentId);
              },
              successMessage: 'Trip assigned',
              errorMessage: 'Error assigning trip',
            })
          );
        }
      } else {
        dispatch(
          onTripAssignmentInvalid({
            tripId,
            assignmentId,
            validateAssignmentResponse,
            status,
          })
        );
      }
    },
    multiloadAssign: (
      tripId: string,
      assignmentId: string,
      potentialMultiloadTrips: string[]
    ) => {
      dispatch(
        dispatcherSlice.actions.onMultiloadAssignmentStart({
          tripId,
          assignmentId,
          potentialMultiloadTrips,
        })
      );
    },
    removeTripFromMultiLoad: (tripId: string, multiLoadId: string) => {
      dispatch(
        execTripMenuAction({
          action: async () => {
            await removeTripFromMultiLoad(tripId, multiLoadId);
          },
          successMessage: 'Trip assigned',
          errorMessage: 'Error removing multi-loaded trip',
        })
      );
    },
    removeMultiLoad: (multiLoadId: string) => {
      dispatch(
        execTripMenuAction({
          action: async () => {
            await deleteTripMultiLoad({ id: multiLoadId });
          },
          successMessage: 'Multi-load removed',
          errorMessage: 'Error removing multi-load',
        })
      );
    },
    editMultiLoad: async (trip: DispatchTripRead, assignmentId: string) => {
      const assignmentResponse = await getAssignment({ id: assignmentId });
      if (!assignmentResponse.data) {
        throw new Error(`Error getting assignment with id: ${assignmentId}`);
      }
      dispatch(
        dispatcherSlice.actions.onMultiloadAssignmentEdit({
          trip,
          assignment: assignmentResponse.data,
        })
      );
    },
    reassign: async (
      tripId: string,
      assignmentId: string,
      status: OverrideStatusEnum
    ) => {
      const validateAssignmentResponse =
        await tripRepository.validateAssignment(tripId, assignmentId);
      if (validateAssignmentResponse.can_assign) {
        dispatch(
          execTripMenuAction({
            action: async () => {
              await tripRepository.reassign(tripId, assignmentId);
            },
            successMessage: 'Trip reassigned',
            errorMessage: 'Error reassigning trip',
          })
        );
      } else {
        dispatch(
          onTripAssignmentInvalid({
            tripId,
            assignmentId,
            validateAssignmentResponse,
            status,
          })
        );
      }
    },
    unassign: (tripId: string, multiLoadId: string | null) => {
      dispatch(
        execTripMenuAction({
          action: async () => {
            await tripRepository.unassign(tripId);
            if (multiLoadId) {
              await removeTripFromMultiLoad(tripId, multiLoadId);
            }
          },
          successMessage: 'Trip unassigned',
          errorMessage: 'Error unassigning trip',
        })
      );
    },
    complete: (tripId: string) => {
      dispatch(
        execTripMenuAction({
          action: async () => {
            await tripRepository.complete(tripId);
          },
          successMessage: 'Trip completed',
          errorMessage: 'Error completing trip',
        })
      );
    },
    completeWithNoValidations: (tripId: string) => {
      dispatch(
        execTripMenuAction({
          action: async () => {
            await tripRepository.completeWithNoValidations(tripId);
          },
          successMessage: 'Trip completed',
          errorMessage: 'Error completing trip',
        })
      );
    },
    updatePickupTime: ({
      tripId,
      newTime,
    }: {
      tripId: string;
      newTime: string;
    }) => {
      dispatch(
        execTripMenuAction({
          action: async () => {
            // TODO: Actualy update the time
            await tripRepository.updatePickupTime(tripId, newTime);
          },
          successMessage: 'Pickup time updated',
          errorMessage: 'Error updating time',
        })
      );
    },
    updatePickupWaitTime: ({
      tripId,
      waitTimeInSeconds,
    }: {
      tripId: string;
      waitTimeInSeconds: number | null;
    }) => {
      dispatch(
        execTripMenuAction({
          action: async () => {
            // TODO: Actualy update the time
            await tripRepository.updateTrip(tripId, {
              pickup_wait_time_seconds: waitTimeInSeconds,
            } as PatchedTrip);
          },
          successMessage: 'Pickup wait time updated',
          errorMessage: 'Error updating wait time',
        })
      );
    },
    activateWillCall: ({ tripId }: { tripId: string }) => {
      dispatch(setModalConfirmTripActivationTripId(tripId));
      dispatch(showDispatchModal('confirmTripActivation'));
    },
    revertStatus: (tripId: string) => {
      dispatch(
        execTripMenuAction({
          action: async () => {
            await tripRepository.revertStatus(tripId);
          },
          successMessage: 'Trip status reverted',
          errorMessage: 'Error reverting trip status',
        })
      );
    },
    overrideStatus: (tripId: string, newStatus: NewStatusEnum) => {
      dispatch(
        execTripMenuAction({
          action: async () => {
            await tripRepository.overrideStatus(tripId, newStatus);
          },
          successMessage: `Trip status set to ${newStatus}`,
          errorMessage: `Error setting status to ${newStatus}`,
        })
      );
    },
    updateDispatcherNotes: (tripId: string, note: string) => {
      dispatch(
        execTripMenuAction({
          action: async () => {
            await tripRepository.updateTrip(tripId, {
              dispatcher_notes: note,
            } as PatchedTrip);
          },
          successMessage: `Trip Notes Updated`,
          errorMessage: `Error updating trip notes`,
        })
      );
    },
    goToTripSummary: (tripId: string) => {
      navigate(`/trips/${tripId}/summary`);
    },
    rejectMarketplaceRequest: (tripId: string) => {
      dispatch(
        dispatcherSlice.actions.onSingleTripRequestRejection({ tripId })
      );
    },
    acceptMarketplaceRequest: (tripId: string) => {
      dispatch(
        dispatcherSlice.actions.onSingleTripRequestAcceptance({ tripId })
      );
    },
  };
}

type LocationType = 'pickup' | 'dropoff';

function useUpdateDriverNotesActions() {
  const dispatch = useAppDispatch();
  const tripRepository = useTripRepository();

  const updateDriverNotes = (
    tripId: string,
    note: string,
    location: LocationType
  ) => {
    dispatch(
      execTripMenuAction({
        action: async () => {
          const trip = await tripRepository.getTrip(tripId);
          await tripRepository.updateTrip(tripId, {
            [location]: {
              ...trip[location],
              driver_notes: note,
            },
          } as PatchedTrip);
        },
        successMessage: `Driver ${location} notes updated`,
        errorMessage: `Error updating driver ${location} notes`,
      })
    );
  };

  return {
    updateDriverNotes,
  };
}
