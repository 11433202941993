import { DateTime } from 'luxon';

import { AppointmentTimeDisplay } from 'src/common/date/AppointmentTimeDisplay';
import {
  DispatchTripRead,
  RunBambiRunReassignedTripRead,
  RunBambiRunTripRead,
} from 'src/common/external/bambi-api/bambiApi';
import { Badge } from 'src/common/primitives/Badge';
import { formatFullName } from 'src/common/util/formatFullName';

import { isDispatchTrip } from '../../DispatchCalendar/TripCalendarEvent/isDispatchTrip';
import { TripDetailsPopover } from '../../DispatchCalendar/TripCalendarEvent/TripDetailsPopover';
import TripTagDisplay from '../TripTagDisplay';
import { TripCardDropdownMenu } from './TripDropdownMenu';

const BASE_CLASSES =
  'relative block flex-grow rounded-lg border shadow-sm focus:outline-none bg-white';
const WILL_CALL_CLASSES =
  'bg-trip-willcall-bg border-trip-willcall-border border-2';
const REQUESTED_CLASSES = 'bg-trip-requested-bg';

export function TripCard({
  trip,
  hideMenu = false,
  variant = 'default',
}: {
  trip: DispatchTripRead | RunBambiRunTripRead | RunBambiRunReassignedTripRead;
  hideMenu?: boolean;
  variant?: 'default' | 'pickup' | 'dropoff';
}) {
  return (
    <TripDetailsPopover trip={trip}>
      <div
        className={`${BASE_CLASSES} 
        ${trip.status === 'requested' && REQUESTED_CLASSES}
        ${trip.is_non_activated_will_call && WILL_CALL_CLASSES}`}
        data-testid="trip-card"
      >
        <div
          className={`px-3 py-2 ${
            !hideMenu && !('assignment' in trip) ? 'pl-0' : ''
          }`}
        >
          <div className="flex-grow items-center sm:flex sm:justify-between">
            {!hideMenu && !('assignment' in trip) && (
              <div>
                <TripCardDropdownMenu trip={trip} />
              </div>
            )}
            <div className="flex flex-col gap-y-1">
              <div className="flex gap-x-2">
                {variant === 'default' && (
                  <div>
                    <Badge
                      label={DateTime.fromISO(
                        trip.scheduled_pickup_at
                      ).toLocaleString(DateTime.TIME_SIMPLE)}
                      color="blue-dark"
                    />
                  </div>
                )}
                <div>{trip.passenger.full_name}</div>
                {variant === 'dropoff' && (
                  <Badge
                    label={`Dropoff at ${DateTime.fromISO(
                      trip.scheduled_dropoff_at
                    ).toLocaleString(DateTime.TIME_SIMPLE)}`}
                    color="green"
                  />
                )}
                {variant === 'pickup' && (
                  <Badge
                    label={`Pickup at ${DateTime.fromISO(
                      trip.scheduled_pickup_at
                    ).toLocaleString(DateTime.TIME_SIMPLE)}`}
                    color="yellow"
                  />
                )}
              </div>
              <div>
                <p
                  className={`m-0 inline text-left text-xs font-normal leading-5 text-[rgba(102,112,133,1)]`}
                >
                  {variant === 'default' &&
                    `${trip.pickup_address} --> ${trip.dropoff_address}`}
                  {variant === 'pickup' && `${trip.pickup_address}`}
                  {variant === 'dropoff' && `${trip.dropoff_address}`}
                </p>
              </div>
              {trip.appointment_at && (
                <AppointmentTimeDisplay
                  textClass="text-sm"
                  strDate={trip.appointment_at}
                />
              )}
            </div>
            <div className="flex flex-col gap-1">
              {/* TODO: Pretty sure there's a Vehicle/SpaceType badge component */}
              {/* If not, we should create one that's shared between this and the Trip and Fleet views */}
              <Badge label={trip.space_type} color="primary" />
              {trip.status === 'canceled' && (
                <Badge label="canceled" color="red" />
              )}
              <div>
                {'old_assignment' in trip &&
                  // Old assignment might not actually exist
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  !!trip.old_assignment?.driver && (
                    <div className="text-xs line-through opacity-[.75]">
                      {formatFullName(trip.old_assignment.driver)} -
                    </div>
                  )}

                {'assignment' in trip &&
                  // Assignment can indeed not exist here
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  !!trip.assignment?.driver && (
                    <div className="text-xs">
                      {formatFullName(trip.assignment.driver)}
                    </div>
                  )}
              </div>
            </div>
          </div>

          {isDispatchTrip(trip) && trip.tags?.length ? (
            <div className="mt-2 pl-4">
              <TripTagDisplay tags={trip.tags} />
            </div>
          ) : null}
        </div>
        {trip.status === 'requested' && (
          <div className="w-full bg-trip-requested-border px-3 py-2 pl-5 text-sm">
            {'payer' in trip && trip.payer.display_name
              ? trip.payer.display_name
              : 'Requested'}
          </div>
        )}
      </div>
    </TripDetailsPopover>
  );
}
