import { useAppDispatch, useAppSelector } from 'src/app/store';
import { Button } from 'src/common/primitives/Button';

import { dispatcherSlice } from '../dispatcher.slice';
import { MultiloadAssignmentSelection } from './MultiloadAssignmentSelection';
import { MultiloadIcon } from './MultiloadIcon';

export function MultiloadSelectionStep({ onNext }: { onNext: () => void }) {
  const dispatch = useAppDispatch();
  const { selectedTripIdsToMultiload, mode } = useAppSelector(
    (state) => state.dispatcher.multiloadAssignmentFlow
  );
  return (
    <div className="flex max-w-md flex-col gap-4 p-2">
      <div className="flex justify-center">
        <MultiloadIcon />
      </div>
      {mode === 'assign-trip' && (
        <>
          <h2 className="-mb-2 text-center text-xl">
            Do you want to multi-load?
          </h2>
          <p className="text-sm text-gray-500">
            This trip can be multi-loaded with other trips. Select the trips you
            would like to multi-load with.
          </p>
        </>
      )}
      {mode === 'edit-trip-multi-load' && (
        <>
          <h2 className="-mb-2 text-center text-xl">
            Do you want to add or remove trips from this multi-load?
          </h2>
          <p className="text-sm text-gray-500">
            If there are additional potential trips to multi-load they'll appear
            in the list below. Selecting/Deselecting trips will add/remove them
            from the multi-load.
          </p>
        </>
      )}
      <p className="text-sm text-gray-500">
        You'll be able to set a preferred order of pickup/dropoffs in the next
        step.
      </p>
      <div className="mt-2 max-h-[50vh] overflow-auto">
        <MultiloadAssignmentSelection />
      </div>
      <div className="mt-4 flex gap-4">
        <Button
          className="flex-grow"
          onClick={() =>
            dispatch(dispatcherSlice.actions.onMultiloadFlowCancel())
          }
        >
          Cancel
        </Button>
        <Button className="flex-grow" variant="primary" onClick={onNext}>
          {mode === 'assign-trip' &&
            assignTripNextText(selectedTripIdsToMultiload.length)}
          {mode === 'edit-trip-multi-load' &&
            editTripNextText(selectedTripIdsToMultiload.length)}
        </Button>
      </div>
    </div>
  );
}

function assignTripNextText(numSelectedTrips: number) {
  if (numSelectedTrips < 2) {
    return 'Assign without multi-loading';
  }
  return 'Multi-load with selected trips';
}

function editTripNextText(numSelectedTrips: number) {
  if (numSelectedTrips < 2) {
    return 'Unmulti-load these trips';
  }
  return 'Multi-load with selected trips';
}
