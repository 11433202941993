import { useAppDispatch, useAppSelector } from 'src/app/store';
import { Button } from 'src/common/primitives/Button/Button';
import { SearchTypeahead } from 'src/common/SearchTypeahead';
import { useDebouncedSearch } from 'src/common/useDebouncedSearch';

import {
  setCredentialSearchTerm,
  setShowCredentialModal,
} from './credential.slice';

export function CredentialRouteActions() {
  const dispatch = useAppDispatch();
  const credentialSearchTerm = useAppSelector(
    (state) => state.credential.credentialSearchTerm
  );

  const { inputValue, handleInputChange } = useDebouncedSearch(
    setCredentialSearchTerm,
    credentialSearchTerm
  );

  return (
    <>
      <SearchTypeahead
        onChange={handleInputChange}
        placeholder="Search credentials"
        value={inputValue}
      />

      <Button
        onClick={() => {
          dispatch(setShowCredentialModal(true));
        }}
        variant="primary"
      >
        Add Credential
      </Button>
    </>
  );
}
