import { useEffect } from 'react';

import { useAppDispatch } from 'src/app/store';

import { setCredentialSearchTerm } from './credential.slice';

export function useCredentialClearSearchTerm() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    return () => {
      dispatch(setCredentialSearchTerm(''));
    };
  }, [dispatch]);
}
