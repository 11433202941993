import { useState } from 'react';

import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { useFeature } from 'src/app/FeatureToggle';
import { RootState, useAppDispatch } from 'src/app/store';
import {
  PayerOrganization,
  useLazyPayersSettingsRetrieveQuery,
  usePayersCreateMutation,
  usePayerSettingsPartialUpdateMutation,
  usePayersPartialUpdateMutation,
  useTilledPaymentMethodCreateMutation,
} from 'src/common/external/bambi-api/bambiApi';
import { FormErrorMessage } from 'src/common/FormErrorMessage';
import { FormField, FormFieldSet } from 'src/common/FormField';
import { FormContextField } from 'src/common/FormField/FormContextField';
import { usePaymentMethodCaptureContext } from 'src/common/forms/PaymentMethodCaptureForm/PaymentMethodCaptureContext';
import { validateBillingAddress } from 'src/common/forms/PaymentMethodCaptureForm/PaymentMethodCaptureForm';
import { SavePaymentMethodCallback } from 'src/common/forms/PaymentMethodCaptureForm/types';
import { MultiSelectPayerField } from 'src/common/MultiSelectPayerContextField';
import { Button } from 'src/common/primitives/Button';
import { SelectOption } from 'src/common/primitives/Select';
import { show } from 'src/common/primitives/Toast/toast.slice';
import formatServerError from 'src/common/util/serverErrorFormatter';

import { FormValues } from './FormValues';
import { payerOrgToPatchedPayerOrgSettings } from './payerOrgToPatchedPayerOrgSettings';
import { payerSignatureOptions } from './payerSignatureOptions';

interface PayersFormStep3Props {
  onClose: () => void;
  onCloseAfterSave: () => void;
  savePaymentMethod: SavePaymentMethodCallback;
  validatePaymentForm: () => Promise<boolean>;
}

export function PayersFormStep3({
  onClose,
  onCloseAfterSave,
  savePaymentMethod,
  validatePaymentForm,
}: PayersFormStep3Props) {
  const {
    isLoading: isMultiloadingFFLoading,
    isEnabled: isMultiloadingEnabled,
  } = useFeature('multi-loading');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const { clearErrors, getValues, watch, setValue } =
    useFormContext<FormValues>();
  const { selectedPayer } = useSelector((state: RootState) => state.payer);
  const [createPayer] = usePayersCreateMutation();
  const [updatePayer] = usePayersPartialUpdateMutation();

  const [getPayerSettings] = useLazyPayersSettingsRetrieveQuery();
  const [updatePayerSettings] = usePayerSettingsPartialUpdateMutation();

  const [savePaymentMethodCreate] = useTilledPaymentMethodCreateMutation();
  const dispatch = useAppDispatch();
  const { areFieldsEmpty } = usePaymentMethodCaptureContext();

  const onSubmit = async () => {
    try {
      setIsSubmitting(true);
      setError(undefined);
      const values = getValues();

      if (!values.payer || !values.payer.value) {
        delete values.payer;
      } else {
        // @ts-ignore - Pulling the ID off of the option
        values.payer = values.payer.value;
      }

      if (!values.default_trip_address) delete values.default_trip_address;
      if (!values.billing_address) delete values.billing_address;

      let payer;
      if (!selectedPayer?.id) {
        payer = await createPayer({
          payerOrganization: values as PayerOrganization,
        }).unwrap();
      } else {
        payer = await updatePayer({
          id: selectedPayer.id,
          patchedPayerOrganization: values as PayerOrganization,
        }).unwrap();
      }

      const currentPayerSettings = await getPayerSettings({
        payerOrganizationId: payer.id,
      }).unwrap();

      const payerSettings = payerOrgToPatchedPayerOrgSettings(values);

      await updatePayerSettings({
        id: currentPayerSettings.id || '',
        patchedPayerOrganizationSettings: payerSettings,
      }).unwrap();

      dispatch(
        show({
          title: 'Successfully updated payer',
          type: 'success',
        })
      );

      const billingAddress = {
        city: values.billing_details?.city,
        country: values.billing_details?.country,
        postalCode: values.billing_details?.postalCode,
        state: values.billing_details?.state,
        streetAddress1: values.billing_details?.streetAddress1,
        streetAddress2: values.billing_details?.streetAddress2,
      };

      const validBillingAddress = validateBillingAddress(billingAddress);

      const billingName =
        values.billing_details?.billingName ?? values.billing_name;
      const validPaymentForm =
        (await validatePaymentForm()) && billingName && validBillingAddress;

      // Skip saving payment method
      if (areFieldsEmpty()) {
        onCloseAfterSave();
        return;
      }
      // Payment method form is invalid
      else if (!validPaymentForm) {
        dispatch(
          show({
            type: 'error',
            title: 'Unable to save payment method',
            description: 'Payment details are invalid, or missing',
          })
        );
      }
      // Payment method form is valid, attempt to save
      else {
        try {
          const savedPaymentMethod = await savePaymentMethod({
            type: 'card',
            billing_details: {
              name: billingName,
              address: validBillingAddress
                ? {
                    ...validBillingAddress,
                    zip: validBillingAddress.postalCode,
                  }
                : undefined,
            },
          });

          // Attach payment method to pax
          await savePaymentMethodCreate({
            tilledPaymentMethodInput: {
              payer_id: payer.id,
              payer_type: 'payer_organization',
              payment_method_id: savedPaymentMethod.id,
            },
          });
          dispatch(
            show({
              title: 'Saved payment method',
              type: 'success',
            })
          );
          onCloseAfterSave();
        } catch (e) {
          dispatch(
            show({
              title: 'Failed to save payment method',
              description: formatServerError(e),
              type: 'error',
            })
          );
        }
      }
    } catch (err) {
      const errorMessage = formatServerError(err);
      setError(errorMessage);
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <div className="flex flex-col gap-3 py-4">
        <div>
          <h2 className="text-xl">Payer Specific Settings</h2>
          <p>If not specified, this payer will use organization defaults</p>
          {error && (
            <div className="pt-2">
              <FormErrorMessage>{error}</FormErrorMessage>
            </div>
          )}
          <div className="py-2">
            <FormFieldSet
              Fields={
                <FormContextField name="default_trip_address">
                  {({ field, fieldState }) => {
                    return (
                      <FormField
                        colSpan={12}
                        label="Default Trip Address"
                        type="location-typeahead"
                        inputProps={{
                          id: 'default_trip_address',
                        }}
                        value={field.value}
                        onChange={(option: SelectOption) => {
                          // setValue doesn't trigger revalidation for some reason
                          clearErrors('default_trip_address');
                          field.onChange({
                            location: { place_id: option.value },
                            label: option.label,
                          });
                        }}
                        options={
                          selectedPayer?.default_trip_address
                            ? [
                                {
                                  label:
                                    selectedPayer.default_trip_address.location
                                      ?.label || '',
                                  value:
                                    selectedPayer.default_trip_address.location
                                      ?.place_id || '',
                                },
                              ]
                            : []
                        }
                        error={
                          field.errors?.default_trip_address?.place_id
                            ?.message && !field.value?.place_id
                            ? 'Please select a valid location'
                            : undefined
                        }
                      />
                    );
                  }}
                </FormContextField>
              }
            />
          </div>
          {!isMultiloadingFFLoading && isMultiloadingEnabled && (
            <div className="py-2">
              <FormFieldSet
                legend="Multi-loading Configuration"
                Fields={
                  <>
                    <FormContextField name="can_multiload">
                      {({ field }) => {
                        return (
                          <FormField
                            colSpan={12}
                            type="switch"
                            label="Enable Multi-loading"
                            inputProps={{
                              value: field.value,
                              onChange: field.onChange,
                              id: 'enable_multi_loading',
                            }}
                          />
                        );
                      }}
                    </FormContextField>
                    <FormContextField name="multiloading_partners">
                      {({ field }) => {
                        const canMultiload = watch('can_multiload');
                        const id = getValues('id');

                        if (!canMultiload && field.value.length > 0) {
                          setValue('multiloading_partners', []);
                        }

                        return (
                          <div className="col-span-12">
                            <MultiSelectPayerField
                              fieldPath="multiloading_partners"
                              fieldLabel="Allow multi-loading with"
                              disabled={!canMultiload}
                              selectedPayerId={id || undefined}
                              canMultiload
                            />
                            <div className="pt-1 pl-1 text-xs text-gray-600">
                              Partners must have multi-loading enabled.
                            </div>
                          </div>
                        );
                      }}
                    </FormContextField>
                  </>
                }
              />
            </div>
          )}
          <div className="py-2">
            <FormFieldSet
              legend="Required Signatures"
              Fields={
                <>
                  <FormContextField name="requires_signature_pickup_driver">
                    {({ field }) => {
                      return (
                        <FormField
                          label="Driver on Pickup"
                          type="select"
                          inputProps={{
                            ...field,
                            id: 'requires_signature_pickup_driver',
                          }}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          options={payerSignatureOptions}
                          value={field.value}
                        />
                      );
                    }}
                  </FormContextField>
                  <FormContextField name="requires_signature_dropoff_driver">
                    {({ field }) => {
                      return (
                        <FormField
                          label="Driver on Dropoff"
                          type="select"
                          inputProps={{
                            ...field,
                            id: 'requires_signature_dropoff_driver',
                          }}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          options={payerSignatureOptions}
                          value={field.value}
                        />
                      );
                    }}
                  </FormContextField>
                  <FormContextField name="requires_signature_pickup_passenger">
                    {({ field }) => {
                      return (
                        <FormField
                          label="Passenger on Pickup"
                          type="select"
                          inputProps={{
                            ...field,
                            id: 'requires_signature_pickup_passenger',
                          }}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          options={payerSignatureOptions}
                          value={field.value}
                        />
                      );
                    }}
                  </FormContextField>

                  <FormContextField name="requires_signature_dropoff_passenger">
                    {({ field }) => {
                      return (
                        <FormField
                          label="Passenger on Dropoff"
                          type="select"
                          inputProps={{
                            ...field,
                            id: 'requires_signature_dropoff_passenger',
                          }}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          options={payerSignatureOptions}
                          value={field.value}
                        />
                      );
                    }}
                  </FormContextField>
                </>
              }
            />
          </div>
          <div className="py-2">
            <FormFieldSet
              legend="Pickup/Dropoff Times"
              Fields={
                <>
                  <FormContextField name="pickup_default_early_seconds">
                    {({ field }) => {
                      return (
                        <FormField
                          label="Pickup Early (minutes)"
                          type="number"
                          inputProps={{
                            ...field,
                            id: 'pickup_default_early_seconds',
                          }}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          value={field.value}
                        />
                      );
                    }}
                  </FormContextField>
                  <FormContextField name="pickup_default_late_seconds">
                    {({ field }) => {
                      return (
                        <FormField
                          label="Pickup Late (minutes)"
                          type="number"
                          inputProps={{
                            ...field,
                            id: 'pickup_default_late_seconds',
                          }}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          value={field.value}
                        />
                      );
                    }}
                  </FormContextField>
                  <FormContextField name="pickup_appointment_early_seconds">
                    {({ field }) => {
                      return (
                        <FormField
                          label="Appointment Early (minutes)"
                          type="number"
                          inputProps={{
                            ...field,
                            id: 'pickup_appointment_early_seconds',
                          }}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          value={field.value}
                        />
                      );
                    }}
                  </FormContextField>
                  <FormContextField name="pickup_appointment_late_seconds">
                    {({ field }) => {
                      return (
                        <FormField
                          label="Appointment Late  (minutes)"
                          type="number"
                          inputProps={{
                            ...field,
                            id: 'pickup_appointment_late_seconds',
                          }}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          value={field.value}
                        />
                      );
                    }}
                  </FormContextField>
                  <FormContextField name="pickup_will_call_early_seconds">
                    {({ field }) => {
                      return (
                        <FormField
                          label="Will-call Early (minutes)"
                          type="number"
                          inputProps={{
                            ...field,
                            id: 'pickup_will_call_early_seconds',
                          }}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          value={field.value}
                        />
                      );
                    }}
                  </FormContextField>
                  <FormContextField name="pickup_will_call_late_seconds">
                    {({ field }) => {
                      return (
                        <FormField
                          label="Will-call Late (minutes)"
                          type="number"
                          inputProps={{
                            ...field,
                            id: 'pickup_will_call_late_seconds',
                          }}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          value={field.value}
                        />
                      );
                    }}
                  </FormContextField>
                </>
              }
            />
          </div>
          <div className="py-2">
            <FormFieldSet
              legend="Notes"
              Fields={
                <>
                  <FormContextField name="dispatcher_notes">
                    {({ field }) => {
                      return (
                        <FormField
                          label="Dispatcher Notes"
                          type="textarea"
                          inputProps={{
                            ...field,
                            id: 'dispatcher_notes',
                          }}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          value={field.value}
                        />
                      );
                    }}
                  </FormContextField>
                  <FormContextField name="admin_notes">
                    {({ field }) => {
                      return (
                        <FormField
                          label="Admin Notes"
                          type="textarea"
                          inputProps={{
                            ...field,
                            id: 'admin_notes',
                          }}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          value={field.value}
                        />
                      );
                    }}
                  </FormContextField>
                </>
              }
            />
          </div>
        </div>
      </div>
      <div className="mt-8 grid grid-cols-2 gap-4">
        <Button
          dataTestId="step3-cancel"
          onClick={() => {
            onClose();
          }}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          onClick={() => onSubmit()}
          className="w-full flex-grow"
          variant="primary"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Saving...' : 'Save'}
        </Button>
      </div>
    </>
  );
}
