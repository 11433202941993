import { createColumnHelper } from '@tanstack/react-table';

import { CredentialRead } from 'src/common/external/bambi-api/bambiApi';

import { EditCredentialButton } from './EditCredentialButton';

const columnHelper = createColumnHelper<CredentialRead>();

export const columns = [
  columnHelper.accessor('membership', {
    id: 'membership',
    header: 'Member',
    cell: ({ row }) => {
      return (
        `${row.original.membership?.first_name} ${row.original.membership?.last_name}` ||
        ''
      );
    },
  }),
  columnHelper.accessor('vehicle', {
    id: 'vehicle',
    header: 'Vehicle',
    cell: ({ row }) => {
      return row.original.vehicle?.nickname || '';
    },
  }),
  columnHelper.accessor('credential_type.name', {
    id: 'name',
    header: 'Name',
  }),
  columnHelper.accessor('credential_type.description', {
    id: 'description',
    header: 'Description',
  }),
  columnHelper.accessor('expiration_date', {
    id: 'expiration_date',
    header: 'Expiration Date',
  }),

  columnHelper.display({
    id: 'actions',
    header: 'Edit',
    cell: (props) => <EditCredentialButton credential={props.row.original} />,
  }),
];
