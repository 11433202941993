import { createSlice } from '@reduxjs/toolkit';

import {
  CredentialRead,
  CredentialTypeRead,
} from '../external/bambi-api/bambiApi';
import { SelectOption } from '../primitives/Select';

export interface ICredentialState {
  credentialSearchTerm: string;
  selectedCredential?: CredentialRead;
  selectedCredentialType?: CredentialTypeRead;
  selectedCredentialTypeOption?: SelectOption;
  newCredentialTypeName: string;
  showCredentialModal: boolean;
  showCredentialTypeModal: boolean;
}

export const initialState: ICredentialState = {
  credentialSearchTerm: '',
  selectedCredential: undefined,
  selectedCredentialType: undefined,
  selectedCredentialTypeOption: undefined,
  newCredentialTypeName: '',
  showCredentialModal: false,
  showCredentialTypeModal: false,
};

export const credentialSlice = createSlice({
  name: 'credential',
  initialState,
  reducers: {
    setCredentialSearchTerm: (state, action) => {
      state.credentialSearchTerm = action.payload;
    },
    setSelectedCredential: (state, action) => {
      state.selectedCredential = action.payload;
    },
    setSelectedCredentialType: (state, action) => {
      state.selectedCredentialType = action.payload;
    },
    setSelectedCredentialTypeOption: (state, action) => {
      state.selectedCredentialTypeOption = action.payload;
    },
    setNewCredentialTypeName: (state, action) => {
      state.newCredentialTypeName = action.payload;
    },
    setShowCredentialModal: (state, action) => {
      state.showCredentialModal = action.payload;
    },
    setShowCredentialTypeModal: (state, action) => {
      state.showCredentialTypeModal = action.payload;
    },
  },
});

export const {
  setCredentialSearchTerm,
  setSelectedCredential,
  setSelectedCredentialType,
  setSelectedCredentialTypeOption,
  setNewCredentialTypeName,
  setShowCredentialModal,
  setShowCredentialTypeModal,
} = credentialSlice.actions;
